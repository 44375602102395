import React from "react";
import Logout from "./logout";

const HomePage = () => {
    return (
        <div>
            <h1>Welcome to the Home Page</h1>
            <Logout></Logout>
        </div>
    );
};

export default HomePage;
