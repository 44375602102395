import axios from "axios";

const scheduleTokenRefresh = () => {
    const interval = setInterval(async () => {
        try {
            const refreshToken = localStorage.getItem("refresh_token");
            if (!refreshToken) {
                throw new Error("No refresh token available");
            }

            const response = await axios.post("http://localhost:8000/api/token/refresh/", {
                refresh: refreshToken,
            });

            localStorage.setItem("access_token", response.data.access);
            console.log("Access token refreshed");
        } catch (error) {
            console.error("Error refreshing access token:", error);
            clearInterval(interval); // 停止刷新
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            window.location.href = "/login"; // 重定向到登录页面
        }
    }, 4 * 60 * 1000); // 每 4 分钟刷新一次（略短于 Access Token 的有效期）
};

export default scheduleTokenRefresh;
