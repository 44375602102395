import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://easyfood.burning233.top/api/",
    headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    withCredentials: true, // 确保跨域请求时发送 Cookie
});

// 获取 CSRF Token
const getCSRFToken = () => {
    const name = "csrftoken";
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
        const [key, value] = cookie.split("=");
        if (key === name) return value;
    }
    return null;
};

// 请求拦截器：为每个请求添加 CSRF Token
axiosInstance.interceptors.request.use((config) => {
    const csrfToken = getCSRFToken();
    if (csrfToken) {
        config.headers["X-CSRFToken"] = csrfToken; // 添加 CSRF Token 到请求头
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});


// 自动刷新令牌逻辑
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response.status === 401) {
            try {
                const refreshResponse = await axios.post(
                    "https://easyfood.burning233.top/api/token/refresh/",
                    { refresh: localStorage.getItem("refresh_token") }
                );
                const { access } = refreshResponse.data;
                localStorage.setItem("access_token", access);
                error.config.headers.Authorization = `Bearer ${access}`;
                return axios(error.config);
            } catch (err) {
                // 如果刷新失败，强制登出
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                window.location.href = "/login";
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
