import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem("access_token"); // 检查本地存储是否有 Token

    if (!isAuthenticated) {
        // 如果未登录，跳转到登录页面
        return <Navigate to="/login" replace />;
    }

    // 如果已登录，渲染目标页面
    return children;
};

export default PrivateRoute;
