import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import axiosInstance from "./axiosinstance";

const Login = () => {
    const handleSuccess = async (credentialResponse) => {
        try {
            const response = await axiosInstance.post("https://easyfood.burning233.top/api/google/login/", {
                token: credentialResponse.credential,
            });
            const { access, refresh } = response.data;

            // 保存 JWT 到本地存储
            localStorage.setItem("access_token", access);
            localStorage.setItem("refresh_token", refresh);

            console.log("Login successful!");
            window.location.href = '/';
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    const handleError = () => {
        console.error("Google Login Failed");
    };

    return (
        <GoogleOAuthProvider clientId="754505196110-b0fjluv203jk6nmbsf9vjn9njn09fkve.apps.googleusercontent.com">
            <div>
                <h2>Login with Google</h2>
                <GoogleLogin
                    onSuccess={handleSuccess}
                    onError={handleError}
                />
            </div>
        </GoogleOAuthProvider>
    );
};

export default Login;
