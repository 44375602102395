import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // 清除本地存储数据
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");

        console.log("Logged out successfully!");
        navigate("/login"); // 跳转回登录页面
    };

    return (
        <button onClick={handleLogout}>Logout</button>
    );
};

export default Logout;
